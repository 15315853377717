import React from "react";
import { v4 } from "uuid";
import styled from "styled-components";
import BreakpointUp from "../Media/BreakpointUp";

const Card = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const CardTitle = styled.h3`
  margin: 0 0 5px;
  color: #002337;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  font-family: "Fira Sans", serif;
`;
const CardFigure = styled.div`
  position: relative;
  width: 130px;
  height: 130px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .gatsby-image-wrapper {
    max-width: 86px;
    width: 100%;
  }
`;
const CardContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
`;
const CardDescription = styled.div`
  margin-bottom: 0;
  a{
    color:#002337;
    font-weight: 500;
    &:hover, &:focus{
      color:#f99207;
    }
  }
`;
const StepGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 15px;
  padding: 0 15px;
  position: relative;
  ${BreakpointUp.sm`				
		grid-template-columns: repeat(2,1fr);		
		padding:0;
	`}
  ${BreakpointUp.lg`				
		grid-column-gap: 30px;
		grid-template-columns: repeat(4,1fr);		
	`}
	&:before {
    ${BreakpointUp.lg`				
			content:'';
			position:absolute;
			top: 70px;
			left: 0;
			right: 0;
			background-image: linear-gradient(to right,#E5E5E5 50%,rgb(255,255,255) 0%);
			background-position: 0px 0px;
			background-size: 10px 2px;
			background-repeat: repeat-x;
			height: 2px;
			max-width: 80%;
			width: 100%;
			margin: 0 auto;
		`}
  }
`;
const StepNumber = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #0a4e82;
  color: #fff;
  font-family: "Fira Sans", serif;
  font-size: 22px;
  line-height: 36px;
  font-weight: 500;
  position: absolute;
  right: -18px;
  top: 0px;
  z-index: 1;
  text-align: center;
`;
const PaymentStep = ({ data }) => {
  return (
    <StepGrid>
      {data.map((item) => {
        return (
          <Card className="card" key={v4()}>
            <CardFigure className="card-img">
              <StepNumber>{item.order}</StepNumber>
              <img src={item.image.file.url} alt={item.image.title} />
            </CardFigure>
            <CardContent className="card-body">
              <CardTitle>{item.title}</CardTitle>
              <CardDescription
                dangerouslySetInnerHTML={{
                  __html: item.description.childMarkdownRemark.html,
                }}
              />
            </CardContent>
          </Card>
        );
      })}
    </StepGrid>
  );
};

export default PaymentStep;
