import React from "react";
import styled from "styled-components";
import axios from "axios";
import { navigate } from "gatsby";
import FormButton from "../button/FormButton";
import BreakpointUp from "../Media/BreakpointUp";

const FormGrid = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  ${BreakpointUp.lg`                  
    grid-template-columns: repeat(2, 1fr);
  `}
`;


class RtoFinanceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            first_name: "",
            email: "",
            mobile_no: "",
            zipcode: "",
            state: "",
            comment: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
    }
    handleChange(event) {
        const { id, value } = event.target;
        this.setState({
            [id]: value,
        });
    }   
    handleSubmit(event) {
    event.preventDefault();
    const { location, formName } = this.props
    const data = {
        source: location.origin,
        page: location.href,
        form: formName,
        first_name: this.state.first_name,
        email: this.state.email,
        mobile_no: this.state.phone_no,
        state: this.state.state,
        zipcode: this.state.zipcode,
        comment: this.state.comment,
    };
    axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN },
    })
        .then((res) => {
        this.setState({
            first_name: "",
            email: "",
            mobile_no: "",
            state: "",
            zipcode: "",
            comment: "",
        });
            if (formName === "RTO Form") {
            navigate("/thank-you-rent-to-own/")  
          } else {
            navigate("/thank-you-for-financing/")
          }
        })
        .catch((error) => {
        alert(error);
        });
    }
  render() {
    return (
        <form onSubmit={this.handleSubmit}>
            <div className="form-group-bordered">
            <input
                type="text"
                id="first_name"
                className="form-control"
                onChange={this.handleChange}
                required
                placeholder="Name"
            />
            </div>
            <FormGrid>
                <div className="form-group-bordered">
                    <input
                    id="email"
                    type="email"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                    placeholder="Email Address"
                    />
                </div>
                <div className="form-group-bordered">
                    <input
                    type="tel"
                    id="mobile_no"
                    className="form-control"
                    pattern="[0-9]{10}"
                    onChange={this.handleChange}
                    required
                    placeholder="Phone Number"
                    />
                </div>
            </FormGrid>
            <FormGrid>
            <div className="form-group-bordered">
                <select
                id="state"
                className="form-control"
                onChange={this.handleChange}
                onBlur={this.handleChange}
                >
                <option value="" selected="selected">
                    Select state
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                </select>
            </div>
            <div className="form-group-bordered">
                <input
                id="zipcode"
                className="form-control"
                onChange={this.handleChange}
                required
                placeholder="Zip Code"
                />
            </div>                
            </FormGrid>
            <div className="form-group-bordered">
                <textarea
                    id="comment"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                    placeholder="Ask Your Query"
                ></textarea>
            </div>
            <div className="form-action text-center">
                <FormButton text="Submit" />
            </div>
        </form>
    );
  }
}

export default RtoFinanceForm;
