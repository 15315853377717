import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { v4 } from "uuid";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionHeader,
  SectionPageTitle,
  BreadCrumb,
} from "../components/section";
import CallToAction from "../components/CallToAction";
import RelatedProductCarousel from "../components/RelatedProductCarousel";
import PhoneIcon from "../components/Icons/PhoneIcon";
import QouteIcon from "../components/Icons/QouteIcon";
import DefaultButton from "../components/button/DefaultButton";
import DefaultOutlineButton from "../components/button/DefaultOutlineButton";
import PaymentStep from "../components/PaymentStep";
import BreakpointUp from "../components/Media/BreakpointUp";
import Content, { HTMLContent } from "../components/Content";
import QuotePopup from "../components/QuotePopup";
import FinanceIcon from "../components/Icons/FinanceIcon";
import RtoIcon from "../components/Icons/RtoIcon";
import generateHTML from "../utils/generateHTML";
import Image from "../components/image";
import FinanceForm from "../components/FinanceForm";

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 35, 55, 0.95);
  }
`;

const SectionCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
`;
const CoverFigure = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 100%;
  display: none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
`;
const CoverImg = styled.div`
  position: relative;
  padding-left: 70px;
`;
const CoverContent = styled.div`
  position: relative;
  padding: 0 15px;
  width: 100%;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p {
    margin-bottom: 30px;
  }
`;

const ButtonToolbar = styled.div`
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: inherit;
  ${BreakpointUp.sm`
    flex-direction:columns;
  `}
  & a, .quote-btn {
    padding: 0 10px;
    + .quote-btn {
      margin-top: 10px;
      ${BreakpointUp.sm`
        margin-top:0px;
      `}
    }
  }
`;

const FormColumn = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
`;

const GridBenefit = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 80px;
`;
const GridBenefitItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`     
    flex: 0 0 50%;
    max-width: 50%;
  `}
`;
const CircleIcon = styled.div`
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  text-align: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  top: -45px;
  ${BreakpointUp.md`     
    width: 120px;
    height: 120px;
    top:-60px;
  `}
  & img {
    max-width: 45px;
    ${BreakpointUp.md`     
      max-width: 70px;
    `}
  }
`;

const CardCircle = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  height: calc(100% - 80px);
  margin-bottom: 80px;
  ${BreakpointUp.md`     
    height: calc(100% - 90px);
    margin-bottom: 90px;
  `}
`;

const CardCircleBody = styled.div`
  background-color: #fff;
  text-align: center;
  padding: 60px 20px 20px 20px;
  ${BreakpointUp.xl`     
    padding: 80px 20px 20px 20px;
  `}
`;

const CardCircleTitle = styled.strong`
  display: block;
  font-family: "Fira Sans", serif;
  color: #002337;
  font-weight: 700;
  margin: 0 0 15px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

const CardCircleText = styled.p`
  a{
    color: #002337;
    font-weight: 500;
    &:hover, &:focus{
      color: #F99207;
    }
  }
`;
const CardIcon = styled.div`
  background-color: #fff;
  border: 1px solid #e5e5e5;
  width: 62px;
  height: 62px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: rotate(45deg) translate(-50%, 0);
  ${BreakpointUp.lg`		
		width: 92px;
		height: 92px;	
	`}
  svg {
    transform: rotate(-45deg);
    width: 40px;
    ${BreakpointUp.lg`		
			width: 60px;
		`}
  }
  ${BreakpointUp.lg`		
		top: 50%;
		left: -15px;
		transform: rotate(45deg) translate(0, -50%);
	`}
 
`;

const ApplyToolbar = styled.div`
  display: block;
  text-align: center;
  > a {
    display: inline-block;
  }
`;

class FinancePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    };
    this.showQuote = this.showQuote.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.add("modal-open");
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    });
    if (typeof window !== `undefined`) {
      document.body.classList.remove("modal-open");
    }
  }
  render() {
    const { location } = this.props;
    const { data } = this.props;
    const pageData = data.contentfulRtoFinancingPage;
    const products = data.allContentfulProduct;
    const PostContent = HTMLContent || Content;
    const { isVisibleQuote } = this.state;
    const productSchema = data.contentfulProduct;
    
    const schema = {
      "@context": "https://schema.org",
      "@type": "Product",
      name: productSchema.productName,
      image: productSchema.featuresImage != null && productSchema.featuresImage[0]['file']['url'],
      description: productSchema.productDescription.productDescription,
      mpn: "MPN",
      SKU: productSchema.productSku,
      brand: {
        "@type": "Thing",
        name: "Mr Carports",
      },
      offers: {
        "@type": "Offer",
        url: `"https://www.mrcarports.com${productSchema.url}`,
        priceValidUntil: `${new Date().getFullYear()}-12-31`,
        priceCurrency: "USD",
        price: "00",
        itemCondition: "NewCondition",
        availability: "InStock",
        seller: {
          "@type": "Organization",
          name: "Mr Carports",
        },
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },
        author: {
          "@type": "Person",
          name: "CTC",
        },
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.2",
        reviewCount: "30",
      },
      category: `<span>${productSchema.productCategory.name}</span>`,
      material: "14 GA Steel",
      width: {
        "@type": "QuantitativeValue",
        value: productSchema.width,
        unitcode: "FOT",
      },
      depth: {
        "@type": "QuantitativeValue",
        value: productSchema.length,
        unitcode: "FOT",
      },
    }
    return (
      <Layout location={location}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          schemaMarkup={schema}
        />
        <SectionHeader
          pt="140px"
          pb="90px"
          xpt="110px"
          xpb="60px"
          bgColor="#F4F8FC"
        >
          <div className="container">
            <SectionCover>
              <CoverContent>
                <BreadCrumb>
                  <Link to="/">Home</Link>/
                  <span>
                    {pageData.url === "/financing" ? "Financing" : "RTO"}
                  </span>
                </BreadCrumb>
                <SectionPageTitle mt="30px" mb="15px" textAlign="left">
                  {pageData.title}
                </SectionPageTitle>
                <SectionDescription mb="50px" textAlign="left">
                  <PostContent
                    content={generateHTML(
                      pageData.heroDescription.childMarkdownRemark.html
                    )}
                  />
                </SectionDescription>
                <ButtonToolbar>
                  <a href="tel:8773505464">
                    <DefaultButton
                      icon={<PhoneIcon />}
                      text="(877) 350-5464"
                      aria-label="phonenumber"
                    />
                  </a>
                  <span
                    className="quote-btn"
                    role="button"
                    tabIndex={0}
                    onClick={this.showQuote}
                    onKeyDown={this.showQuote}
                  >
                    <DefaultOutlineButton
                      icon={<QouteIcon />}
                      text="Request A Quote"
                    />
                  </span>
                </ButtonToolbar>
              </CoverContent>
              <CoverFigure>
                <CoverImg>
                  <Img
                    fluid={pageData.heroImage.fluid}
                    alt={pageData.heroImage.title}
                  />
                  <CardIcon className="card-icon">
                    {pageData.url === "/financing" ? (
                      
                      <FinanceIcon />
                    ) : (                      
                      <RtoIcon />
                    )}
                  </CardIcon>
                </CoverImg>
              </CoverFigure>
            </SectionCover>
          </div>
        </SectionHeader>
        <QuotePopup isVisible={isVisibleQuote} onClose={this.onClosePopup} />
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#ffffff">
          <div className="container">
            <SectionTitle maxWidth="1120px"  mb="30px">
              {pageData.secondSectionTitle}
            </SectionTitle>
            {pageData.secondSectionDescription && (
              <SectionDescription mb="40px" maxWidth="1120px">
                <PostContent
                  content={generateHTML(
                    pageData.secondSectionDescription.childMarkdownRemark.html
                  )}
                />
              </SectionDescription>
            )}
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#002337">
          <BackgroundImage>
            <Image name="benefit-bg.jpg" alt="benefit" />
          </BackgroundImage>
          <div className="container">
            <SectionTitle maxWidth="1120px" color="#fff">{pageData.featuresTitle}</SectionTitle>
            {pageData.featuresDescription && (
              <SectionDescription mb="40px" maxWidth="1120px" color="#fff">
                <PostContent 
                  content={generateHTML(
                    pageData.featuresDescription.childMarkdownRemark.html
                  )}
                />
              </SectionDescription>
            )}
            <GridBenefit>
              {pageData.featuresSection.map((item) => {
                return (
                  <GridBenefitItem key={v4()}>
                    <CardCircle>
                      <CircleIcon>
                        <img src={item.image.file.url} alt={item.image.title} />
                      </CircleIcon>
                      <CardCircleBody>
                        <CardCircleTitle>{item.title}</CardCircleTitle>
                        <CardCircleText
                          dangerouslySetInnerHTML={{
                            __html: item.description.childMarkdownRemark.html,
                          }}
                        />
                      </CardCircleBody>
                    </CardCircle>
                  </GridBenefitItem>
                );
              })}
            </GridBenefit>
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
          <div className="container">
            <FormColumn>
              <SectionTitle mb="40px">{pageData.formTitle}</SectionTitle>
              {pageData.url === "/financing" ? (               
                <FinanceForm location={location} formName="Financing Form" />              
              ) : (               
                <FinanceForm location={location} formName="RTO Form" />            
              )}              
            </FormColumn>
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#fff">
          <div className="container">
            <SectionTitle maxWidth="750px">
              {pageData.followSectionTitle}
            </SectionTitle>
            <SectionDescription mb="40px">
              {pageData.followSectionDescription.followSectionDescription}
            </SectionDescription>
            <PaymentStep data={pageData.followSteps} />
            <ApplyToolbar>
              <a href="tel:8773505464">
                <DefaultButton icon={<PhoneIcon />} text="Call Us Now" />
              </a>
            </ApplyToolbar>
          </div>
        </Section>
        <CallToAction sectionData={pageData.ctaSection} />
        <Section pt="90px" pb="90px" xpt="60px" xpb="30px" bg="#F4F8FC">
          <div className="container">
            <SectionTitle maxWidth="750px">
              {pageData.productSectionTitle}
            </SectionTitle>
            {pageData.productSectionDescription && (
              <SectionDescription mb="40px" maxWidth="1120px">
                <PostContent
                  content={generateHTML(
                    pageData.productSectionDescription.childMarkdownRemark.html
                  )}
                />
              </SectionDescription>
            )}
            <RelatedProductCarousel
              data={products}
              showQuote={this.showQuote}
            />
          </div>
        </Section>
      </Layout>
    );
  }
}

export default FinancePage;

export const pageQuery = graphql`
  query FinancePageQuery($id: String!) {
    contentfulRtoFinancingPage(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      url
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        fluid {
          aspectRatio
          base64
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      secondSectionTitle
      secondSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      featuresTitle
      featuresDescription {
        childMarkdownRemark {
          html
        }
      }
      featuresSection {
        order
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          file {
            url
          }
        }
      }
      formTitle
      followSectionTitle
      followSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      followSteps {
        order
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          file {
            url
          }
        }
      }
      ctaSection {
        sectionTitle
        subTitle
        sectionButtonText
        sectionButtonLink
        sectionImages {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          productName
          url
          popularLabel
          image {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
        }
      }
    }
    contentfulProduct {
      id
      productSku
      productName
      width
      height
      length
      totalPrice
      featuresImage {
        title
        file {
          url
        }
      }

      url
      productCategory {
        name
        link
      }
      productDescription {
        productDescription
      }
    }
  }
`;